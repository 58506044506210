export const phoneMask = {
  preProcess: (val: string) => {
    if (val === '7' || val === '8') {
      return '+7(';
    }
    return val;
  },
  mask: '+7(###)-###-##-##',
};

export const innMask = {
  mask: '############',
};

export const ogrnMask = {
  mask: '###############',
};

export const kppMask = {
  mask: '#########',
};

export const bicMask = {
  mask: '#########',
};

export const calcBillMask = {
  mask: '####################',
};

export const correspodentBillMask = {
  mask: '####################',
};

export const dateMask = {
  mask: '##.##.####',
};
